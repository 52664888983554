
            @import "@/assets/sass/vars.portal.scss";
          



























































































































































.table-voucher-report {
    width: 700px;
}
